<!-- =========================================================================================
    File Name: CardBasic.vue
    Description: Basic Cards
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <!-- Caroussel de la page d'accueil , changee le contenu dans data.sliders -->
    <swiper :options="swiperOption" ref="swiper">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide v-for="slider in sliders" :key="slider.title" class="swiper-slide no-padding">
        <div class="content-slider">
          <a :href="slider.link" class="link-slider">
            <img :src="slider.background">
          </a>
        </div>
      </swiper-slide>
     <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>

    <div class=" body">
      <!-- content -->
      <div class="center">
        <div class="bloc">

          <p class="intro">
            Des professionnels en prospection sont à votre disposition
          </p>
        </div>
        <div class="bloc justify">
          <h1>
            Les avantages
          </h1>
          <vs-row vs-w="12">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-xs="12"
                    class="space-10">
              <vx-card class="center equal">

                <img src="@/assets/images/icones/avantage1.png">
                <h2> Des experts à votre service </h2>
                <p>Une cellule spécialisée en prospection dans le domaine de l’immobilier, formée à l’ADN
                  Proprietes-privees.com, vous met en relation avec les vendeurs de votre secteur afin qu'ils vous
                  confient leur bien à la vente.</p>

              </vx-card>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-xs="12"
                    class="space-10">
              <vx-card class="center equal">

                <img src="@/assets/images/icones/avantage2.png">
                <h2> Un gain de temps considérable </h2>
                <p>Le service de prise de RDV vous permet d'optimiser votre prospection téléphonique sur votre secteur.
                  Vous gagnez en efficacité et en notoriété et développez plus rapidement votre activité.
                  !
                </p>

              </vx-card>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-xs="12"
                    class="space-10">
              <vx-card class="center equal">

                <img src="@/assets/images/icones/avantage3.png">
                <h2> Des résultats probants </h2>
                <p>Avec le service de prise de RDV, les conseillers Proprietes-privees.com signe en moyenne un compromis
                  tous les 10 RDV honorés.</p>

              </vx-card>
            </vs-col>
          </vs-row>
        </div>
        <!-- Bloc  temoignage

        <div class="bloc">
          <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
            <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
            <swiper-slide class="swiper-slide temoignage">
              <div class="content-slider">
                <img src="@/assets/images/temoignages/1.png">
                <img src="@/assets/images/temoignages/separateur.png">
                <div class="photo" data-swiper-parallax="-100">

                </div>
                <div class="subtitle font-semibold text-center" data-swiper-parallax="-200">teteteteteettettetete<br>tteteteteteteteet
                </div>
                <div data-swiper-parallax="-300">
                  <span class="nom">Noura Toukabri</span>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          </swiper>
        </div>-->
        <div class="bloc packs" id="packs">
          <h1>
            Les packs
          </h1>
          <vs-row vs-w="12" align="center"
                  justify="center">
            <vs-col :vs-lg="nbFormules" vs-sm="12" vs-xs="12"
                    class="space-10"
                    v-for="formule in formules" :key="formule.id">
              <vx-card class="center equal">
                <img v-if="formule.mediaIcone !== undefined" :src="baseURL+formule.mediaIcone.contentUrl"
                     class="packIcon">
                <h2> {{formule.libelle}}</h2>
                <div v-html="formule.description">
                </div>

                <div class="prix">
                  <h5 v-for="{nb,prix} in formule.prix" :key="nb">
                    Pack {{nb}} rdv : {{prix}}€ HT
                  </h5>
                </div>
                <vs-button class="mt-4 mr-2 shadow-lg" type="gradient" :color="colors.primary"
                           :gradient-color-secondary="colors.primary"
                           @click="linkAdd(formule.libelle)">Passer une commande
                </vs-button>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
        <div class="bloc packs" id="offres" v-if="parseInt(nbOffres) > 0">
          <h1>
            Les Offres actuelles
          </h1>
          <div class="container" style="padding-top: 0 ; padding-bottom: 0">
          <vs-row vs-w="12" align="center"
                  justify="center">
            <vs-col :vs-lg="nbOffres" vs-sm="12" vs-xs="12"
                    class="space-10"
                    v-for="offre in offres" :key="offre.id">
              <vx-card  class="center equal" :style="(offre.mediaHomePage !== null && offre.mediaHomePage !== undefined) ?{ backgroundImage: 'url(' + `${baseURL+offre.mediaHomePage.contentUrl}` + ')'  } : {backgroundColor: white}">
                <div class="background-card" >
                  <h5 class="formule-titre"> {{offre.formuleCommande.libelle}}</h5>
                <h2> {{offre.libelle}}</h2>
                <div v-html="offre.description">
                </div>

                <vs-button class="mt-4 mr-2 shadow-lg" type="gradient" :color="colors.primary"
                           :gradient-color-secondary="colors.primary"
                           @click="linkAdd(offre.formuleCommande.libelle, offre.id)">Passer une commande
                </vs-button>
                </div>
              </vx-card>

            </vs-col>
          </vs-row>
          </div>
        </div>
        <div class="bloc">
          <div class="vx-row ">
            <call-to-action v-if="debutant" title="Vous êtes débutant dans l'immobilier" :subtitle="soustitre"
                            link_url="user-orders-add" link_text="Profitez de l'offre" link_params='{"type":"Debutant"}'
                            background="Slider/bandeau.png" color="black"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import header from '@/assets/images/Slider/header_page_accueil.png';
import axios from '../../axios';
import { colors } from '../../../themeConfig';
import 'swiper/dist/css/swiper.min.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import callToAction from '../components/CallToAction.vue';
import router from '../../router';
import moment from 'moment';

const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  props: ['connected'],
  components: {
    swiper,
    swiperSlide,
    'call-to-action': callToAction,
  },
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
        autoplay: true,
        slidesPerView: 1,
        paginationClickable: true,
        spaceBetween: 30,
        keyboardControl: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      baseURL,
      nbFormules: 0,
      offresPourNego: [],
      formules: [],
      nbOffres: 0,
      offres: [],
      soustitre: String,
      debutant: false,
      colors,
      result: null,
      responseData: '',
      test: '',
      colorx: 'colors.gray',
      popupActive: false,
      linkHistorique: '/Historique',
      sliders: {
        0: {
          classe: 'premium',
          background: header,
          link: '#packs',
        },
      },
    };
  },
  methods: {
    getFormules() {
      axios.get('/api/formule_commandes', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          // this.formules = response.data
          let nb = 0;
          const formulesPack = [];
          response.data.forEach((x) => {
            if (x.active && x.homePage) {
              formulesPack.push(x);
              this.formules.push(x);
              nb++;
            }
          });

          // Nombre de colonnes pour les packs
          this.nbFormules = Math.floor(12 / nb);
        }
      })
        .catch(function (error) {
          this.$toasted.show(error).goAway(1500);
        });
    },
    getOffres() {
      this.checkOffers().then(() => {
        axios.get('/api/sous_formule_commandes', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then((response) => {
          if (response.data !== null) {
            let nb = 0;
            const formulesPack = [];
            response.data.forEach((x) => {
              // Il faut que l'offre soit active, et que sa date de début soit >= aujourd'hui, si la date de fin >= aujourd'hui, elle se désactive automatiquement en back
              if (x.active && moment(x.dateDebut).format('YYYY-MM-DD HH:mm:ss') <= moment(new Date()).format('YYYY-MM-DD HH:mm:ss') && moment(x.dateFin).format('YYYY-MM-DD HH:mm:ss') >= moment(new Date()).format('YYYY-MM-DD HH:mm:ss') && (this.offresPourNego.indexOf(x.id) != -1)) {
                formulesPack.push(x);
                this.offres.push(x);
                nb++;
                if (x.mediaSlider !== null && x.slider !== false && (this.offresPourNego.indexOf(x.id) != -1)) {
                  this.sliders[x.id] = {
                    title: x.libelle,
                    classe: 'premium',
                    background: baseURL + x.mediaSlider.contentUrl,
                    link: '#offres',

                  };
                }
              }
            });
            // Nombre de colonnes pour les packs
            this.nbOffres = Math.floor(12 / nb);
          }
        })
          .catch((error) => {
            this.$toasted.show(error).goAway(1500);
          });
      });
    },
    // Negociateur bénificiant d'une offre débutant
    checkDebutant() {
      axios.get(`/api/commandes/check_offre_debutant?trigramme=${this.$store.state.AppActiveUser.user.trigramme}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          if ((new Date() < new Date(response.data.dateFin)) && (response.data.dateCommande == null)) {
            const date = moment(String(response.data.dateFin)).format('MM/DD/YYYY');
            this.debutant = true;
            this.soustitre = `Profitez de ${response.data.nbRdv} rendez-vous GRATUITS, valables  jusqu'au ${date}`;
          }
        }
      })
        .catch(function (error) {
          this.$toasted.show(error).goAway(1500);
        });
    },
    // Check offers
    async checkOffers() {
      await axios.get(`/api/offres/email/${this.$store.state.AppActiveUser.user.email}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          response.data.forEach((x) => {
            this.offresPourNego.push(x.id);
          });
        }
      })
        .catch(function (error) {
          this.$toasted.show(error).goAway(1500);
        });
    },
    linkAdd(type, promotion) {
      router.push({ name: 'user-orders-add', query: { type, promotion } });
    },
  },
  mounted() {
    this.checkDebutant();
    this.getFormules();
    this.getOffres();
  },
};
</script>
<style>
  [dir] p {
    clear: both;
    margin-bottom: 0px !important;
  }
  .content-slider {
    padding: 0;
  }

  .content-slider img {
    width: 100%
  }

  .swiper-slide.no-padding {
    padding: 0;
  }

  .image2 {
    width: 130px
  }

  [dir] .router-view {
    padding: 2.2rem;
    padding-top: 0;
  }

  .temoignage img {
    display: block;
  }

  .temoignage .subtitle {
    font-size: 18px;
    color: white
  }

  .temoignage .nom {
    font-size: 16px;
    color: #E61E51;
    font-weight: 700;
  }

  .temoignage {
    text-align: center;
    background: url("../../assets/images/Slider/temoignage.png");
    background-size: cover;
  }

</style>
